import './App.css';
import { Intro } from './Components/intro'
import { Conways } from './Components/conways'
import { conways_data } from './utils';

function App() {
    let condata = conways_data()
    return (
    <>
        <main className="md:w-3/4 sm:w-screen m-auto mx-auto">
            <Intro />
            <div className="md:mx-auto w-3/4 flex md:flex-row sm:flex-col sm:mx-auto sm:py-8 md:my-12" style={{marginTop: 50, borderTop: "solid grey 2px", paddingTop: "5px"}}>
                <div className="md:w-1/2 mx-2">
                    <h1 className="text-2xl">Skills</h1>
                    <ul className="">
                        <li>Database Management</li>
                        <li>Application Development</li>
                        <li>Web Servers</li>
                        <li>Interpersonal Skills</li>
                        <li>Information Security</li>
                        <li>Critical Thinking</li>
                        <li>Networking</li>
                        <li>Software Reverse Engineering</li>
                    </ul>
                </div>
                <div className="md:w-1/2 mx-4">
                <h1 className="text-2xl ">Programming languages</h1>
                    <ul>
                        <li>Javascript/Typescript</li>
                        <li>Rust</li>
                        <li>Python</li>
                        <li>Lua</li>
                        <li>Bash</li>
                        <li>Golang</li>
                        <li>C++</li>
                        <li>C#</li>
                    </ul>
                </div>
                <div className="md:w-1/2 mx-4">
                <h1 className="text-2xl ">Has experience with</h1>
                <ul>
                    <li>Reactjs</li>
                    <li>Blazor</li>
                    <li>Jquery</li>
                    <li>Tailwind</li>
                    <li>Webpack</li>
                    <li>VirtualBox</li>
                    <li>Raylib</li>
                </ul>
                </div>
            </div>
            <div className=' mx-auto sm:w-3/4 '>
                <br />
                <div style={{textAlign: "center"}}>
                    <p className=' text-2xl'>Conways Game of Life</p>
                    <span >Click on the grid to add or remove a tile. Then press play to start the simulation</span>

                </div>
                <br />
                <Conways cells={condata}></Conways>
                <div style={{textAlign: "center"}} className='mx-auto my-10 text-center sm:w-3/4'>
                    
                    <br />
                    <br />
                    This fully functional implementation of conways game of life uses a number of different technologies. 
                    The internal logic and state management was written in Rust as can be seen in this <a style={{textDecoration:"underline", color: "#007FFF"}} className='font-medium text-blue-600  hover:underline' href="https://remote.collinswisher.net/gitbucket/Collin/GameOfLife">repo</a>.
                    The Rust was compiled to web assembly, javascript bindings were created using wasm-bindgen and the whole project was packaged using webpack.
                    The board is rendered on a canvas controlled by javascript/react.
                </div><br />
            </div>
        </main>       
    </>
    );
}

export default App;
