import { isMobile } from 'react-device-detect'


export function conways_data() {
    let condata = ""
        if(isMobile) {
            condata = "10 9,11 11,14 12,10 13,10 8,6 10,8 14,8 9,8 15,5 12,13 12,4 11,12 10,10 7,7 11,5 10,12 12,8 8,8 13,14 11,9 10,6 12,4 10,9 7,9 12,10 14,9 15,13 10,"
        }
        else condata = "2 2,37 1,1 0,24 21,21 15,20 14,0 2,1 39,37 37,1 2,1 37,15 19,2 38,25 19,37 2,23 20,21 25,19 24,20 17,16 21,38 39,39 2,17 20,19 16,26 20,20 26,38 2,19 15,24 19,37 38,0 37,21 24,20 23,16 19,19 25,15 21,14 20,2 37,21 16,2 1,39 37,38 0,38 37,25 21,"
        // else condata = "2 2,3 2,24 21,21 15,20 14,37 37,1 2,15 19,25 19,2 0,21 25,19 24,23 19,23 20,20 17,16 21,17 20,19 16,26 20,21 23,17 21,20 26,3 1,19 15,24 19,21 24,20 23,16 19,19 25,15 21,19 17,14 20,36 37,21 16,36 38,37 39,38 37,25 21,"
        return condata
    }